.content_data[data-v-18db3dae] {
  background-color: #d8ecff;
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;
}
.content_data .iconfont[data-v-18db3dae] {
  font-size: 18px;
  color: #17a2b8;
}
.each[data-v-18db3dae] {
  width: 120px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}
.each span[data-v-18db3dae] {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon_bg[data-v-18db3dae] {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}
.data_time[data-v-18db3dae] {
  position: absolute;
  right: 10px;
  top: 10px;
}
.content_select[data-v-18db3dae] {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
[data-v-18db3dae] .el-input {
  height: 24px;
  line-height: 24px;
}